import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import { RouterName } from "./RouterName";
import { dataLocalStorage, getLocalStorage } from "src/helper/publicFunction";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

/* ****Pages***** */
const AdminLogs = Loadable(lazy(() => import("src/pages/admins_logs/view")));

const Users = Loadable(lazy(() => import("src/pages/users/view")));

const OurTeam = Loadable(
  lazy(() => import("src/pages/landingPage/ourTeam/view"))
);


const KeyFeature = Loadable(lazy(()=> import("../pages/keyFeature/view")));

const Products = Loadable(
  lazy(() => import("src/pages/landingPage/products/view"))
);

const Services = Loadable(
  lazy(() => import("src/pages/landingPage/services/view"))
);


const Address = Loadable(lazy(() => import("src/pages/addresses/addresses/view")));
const AddressesCareer = Loadable(lazy(() => import("src/pages/addresses/address_careers/view")));
const AddressesEmail = Loadable(lazy(() => import("src/pages/addresses/address_emails/view")));
const AddressesWorkingHours = Loadable(lazy(() => import("src/pages/addresses/address_workinghours/view")));

const Settings = Loadable(lazy(() => import("src/pages/settings/view")));
const LegalInformation = Loadable(
  lazy(() => import("src/pages/settings/legal_information"))
);
const ContactAs = Loadable(lazy(() => import("src/pages/contact_as/view")));


const OurClient = Loadable(
  lazy(() => import("src/pages/landingPage/ourClient/view"))
);
const OurPartner = Loadable(
  lazy(() => import("src/pages/landingPage/ourPartner/view"))
);
const Testimonials = Loadable(
  lazy(() => import("src/pages/landingPage/testimonials/view"))
);



const AdminsPage = Loadable(lazy(() => import("../pages/admins/admins/view")));
const PrivilegesPage = Loadable(
  lazy(() => import("../pages/admins/privileges/view"))
);

const SliderShowPage = Loadable(
  lazy(() => import("../pages/landingPage/slidershow/view"))
);
const Industries = Loadable(
  lazy(() => import("../pages/industries/industries/view"))
);

const News = Loadable(
  lazy(() => import("../pages/landingPage/news/view"))
);


// form elements
const MuiAutoComplete = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiAutoComplete"))
);
const MuiButton = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiButton"))
);
const MuiCheckbox = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiCheckbox"))
);
const MuiRadio = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiRadio"))
);
const MuiSlider = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiSlider"))
);
const MuiDateTime = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiDateTime"))
);
const MuiSwitch = Loadable(
  lazy(() => import("../views/forms/form-elements/MuiSwitch"))
);

// form layout
const FormLayouts = Loadable(lazy(() => import("../views/forms/FormLayouts")));
const FormCustom = Loadable(lazy(() => import("../views/forms/FormCustom")));
const FormWizard = Loadable(lazy(() => import("../views/forms/FormWizard")));
const FormValidation = Loadable(
  lazy(() => import("../views/forms/FormValidation"))
);
const QuillEditor = Loadable(
  lazy(() => import("../views/forms/quill-editor/QuillEditor"))
);
const FormHorizontal = Loadable(
  lazy(() => import("../views/forms/FormHorizontal"))
);
const FormVertical = Loadable(
  lazy(() => import("../views/forms/FormVertical"))
);

// tables
const BasicTable = Loadable(lazy(() => import("../views/tables/BasicTable")));
const CollapsibleTable = Loadable(
  lazy(() => import("../views/tables/CollapsibleTable"))
);
const EnhancedTable = Loadable(
  lazy(() => import("../views/tables/EnhancedTable"))
);
const FixedHeaderTable = Loadable(
  lazy(() => import("../views/tables/FixedHeaderTable"))
);
const PaginationTable = Loadable(
  lazy(() => import("../views/tables/PaginationTable"))
);
const SearchTable = Loadable(lazy(() => import("../views/tables/SearchTable")));

// chart
const LineChart = Loadable(lazy(() => import("../views/charts/LineChart")));
const GredientChart = Loadable(
  lazy(() => import("../views/charts/GredientChart"))
);
const DoughnutChart = Loadable(
  lazy(() => import("../views/charts/DoughnutChart"))
);
const AreaChart = Loadable(lazy(() => import("../views/charts/AreaChart")));
const ColumnChart = Loadable(lazy(() => import("../views/charts/ColumnChart")));
const CandlestickChart = Loadable(
  lazy(() => import("../views/charts/CandlestickChart"))
);
const RadialbarChart = Loadable(
  lazy(() => import("../views/charts/RadialbarChart"))
);

// ui
const MuiAlert = Loadable(
  lazy(() => import("../views/ui-components/MuiAlert"))
);
const MuiAccordion = Loadable(
  lazy(() => import("../views/ui-components/MuiAccordion"))
);
const MuiAvatar = Loadable(
  lazy(() => import("../views/ui-components/MuiAvatar"))
);
const MuiChip = Loadable(lazy(() => import("../views/ui-components/MuiChip")));
const MuiDialog = Loadable(
  lazy(() => import("../views/ui-components/MuiDialog"))
);
const MuiList = Loadable(lazy(() => import("../views/ui-components/MuiList")));
const MuiPopover = Loadable(
  lazy(() => import("../views/ui-components/MuiPopover"))
);
const MuiRating = Loadable(
  lazy(() => import("../views/ui-components/MuiRating"))
);
const MuiTabs = Loadable(lazy(() => import("../views/ui-components/MuiTabs")));
const MuiTooltip = Loadable(
  lazy(() => import("../views/ui-components/MuiTooltip"))
);
const MuiTransferList = Loadable(
  lazy(() => import("../views/ui-components/MuiTransferList"))
);
const MuiTypography = Loadable(
  lazy(() => import("../views/ui-components/MuiTypography"))
);

// authentication
const Login = Loadable(
  lazy(() => import("../views/authentication/auth/Login"))
);

const ForgotPassword = Loadable(
  lazy(() => import("../views/authentication/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("src/views/authentication/auth/ResetPassword"))
);

const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Maintenance = Loadable(
  lazy(() => import("../views/authentication/Maintenance"))
);
let data = getLocalStorage(dataLocalStorage.privileges);

console.log({data123:data["4"]});

const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      {
        path: "/",
        exact: true,
        element: (
          <Navigate
            to={
              data["13"]?.access_read == 1
                ? RouterName.home.home
                : data["2"]?.access_read == 1
                ? RouterName.users.users
                : data["1"]?.access_read == 1
                ? RouterName.admins.admins
                : data["3"]?.access_read == 1
                ? RouterName.flashcard.flashcard
                : data["4"]?.access_read == 1
                ? RouterName.landing_page.slidershow
                : data["5"]?.access_read == 1
                ? RouterName.settings.settings
                : data["6"]?.access_read == 1
                ? RouterName.how_it_work.how_it_work
                : data["7"]?.access_read == 1
                ? RouterName.activities.category
                : data["8"]?.access_read == 1
                ? RouterName.topics.topics
                : data["9"]?.access_read == 1
                ? RouterName.ContactAs.ContactAs
                : data["10"]?.access_read == 1
                ? RouterName.addresses.addresses
                : data["16"]?.access_read == 1
                ? RouterName.addresses.addresses_careers
                : data["17"]?.access_read == 1
                ? RouterName.faqs.faqs
                : data["11"]?.access_read == 1
                ? RouterName.tips.tips
                : data["12"]?.access_read == 1
                ? RouterName.admins_logs.admins_logs
                : null
            }
          />
        ),
      },

      data["1"]?.access_read == 1 && {
        path: RouterName.admins.admins,
        exact: true,
        element: <AdminsPage />,
      },
      data["2"]?.access_read == 1 && {
        path: RouterName.users.users,
        exact: true,
        element: <Users />,
      },
      data["1"]?.access_read == 1 && {
        path: RouterName.admins.privileges,
        exact: true,
        element: <PrivilegesPage />,
      },
      data["4"]?.access_read == 1 && {
        path: RouterName.landing_page.keyfeature,
        exact: true,
        element: <KeyFeature />,
      },

      data["4"]?.access_read == 1 && {
        path: RouterName.landing_page.slidershow,
        exact: true,
        element: <SliderShowPage />,
      },

      data["4"]?.access_read == 1 && {
        path: RouterName.landing_page.news,
        exact: true,
        element: <News />,
      },
      data["4"]?.access_read == 1 && {
        path: RouterName.landing_page.our_client,
        exact: true,
        element: <OurClient />,
      },
      data["4"]?.access_read == 1 && {
        path: RouterName.landing_page.our_partner,
        exact: true,
        element: <OurPartner />,
      },

      data["4"]?.access_read == 1 && {
        path: RouterName.landing_page.testimonials,
        exact: true,
        element: <Testimonials />,
      },

      data["4"]?.access_read == 1 && {
        path: RouterName.landing_page.products,
        exact: true,
        element: <Products />,
      },
      data["4"]?.access_read == 1 && {
        path: RouterName.landing_page.services,
        exact: true,
        element: <Services />,
      },
      data["4"]?.access_read == 1 && {
        path: RouterName.industries.industries,
        exact: true,
        element: <Industries />,
      },

      data["4"]?.access_read == 1 && {
        path: RouterName.landing_page.our_team,
        exact: true,
        element: <OurTeam />,
      },
      data["5"]?.access_read == 1 && {
        path: RouterName.settings.settings,
        exact: true,
        element: <Settings />,
      },
      data["5"]?.access_read == 1 && {
        path: RouterName.settings.legal_information,
        exact: true,
        element: <LegalInformation />,
      },

      data["9"]?.access_read == 1 && {
        path: RouterName.ContactAs.ContactAs,
        exact: true,
        element: <ContactAs />,
      },
      
      data["12"]?.access_read == 1 && {
        path: RouterName.admins_logs.admins_logs,
        exact: true,
        element: <AdminLogs />,
      },
      
      data["16"]?.access_read == 1 && {
        path: RouterName.addresses.addresses,
        exact: true,
        element: <Address />,
      },
      data["16"]?.access_read == 1 && {
        path: RouterName.addresses.addresses_careers,
        exact: true,
        element: <AddressesCareer />,
      },
      data["16"]?.access_read == 1 && {
        path: RouterName.addresses.addresses_emails,
        exact: true,
        element: <AddressesEmail />,
      },
       data["16"]?.access_read == 1 && {
        path: RouterName.addresses.addresses_workinghours,
        exact: true,
        element: <AddressesWorkingHours />,
      },

      // -----------------------------------------------------------------------------

      {
        path: "/forms/form-elements/autocomplete",
        element: <MuiAutoComplete />,
      },
      { path: "/forms/form-elements/button", element: <MuiButton /> },
      { path: "/forms/form-elements/checkbox", element: <MuiCheckbox /> },
      { path: "/forms/form-elements/radio", element: <MuiRadio /> },
      { path: "/forms/form-elements/slider", element: <MuiSlider /> },
      { path: "/forms/form-elements/date-time", element: <MuiDateTime /> },
      { path: "/forms/form-elements/switch", element: <MuiSwitch /> },
      { path: "/forms/form-elements/switch", element: <MuiSwitch /> },
      { path: "/forms/quill-editor", element: <QuillEditor /> },
      { path: "/forms/form-layouts", element: <FormLayouts /> },
      { path: "/forms/form-horizontal", element: <FormHorizontal /> },
      { path: "/forms/form-vertical", element: <FormVertical /> },
      { path: "/forms/form-custom", element: <FormCustom /> },
      { path: "/forms/form-wizard", element: <FormWizard /> },
      { path: "/forms/form-validation", element: <FormValidation /> },
      { path: "/tables/basic", element: <BasicTable /> },
      { path: "/tables/collapsible", element: <CollapsibleTable /> },
      { path: "/tables/enhanced", element: <EnhancedTable /> },
      { path: "/tables/fixed-header", element: <FixedHeaderTable /> },
      { path: "/tables/pagination", element: <PaginationTable /> },
      { path: "/tables/search", element: <SearchTable /> },
      { path: "/charts/line-chart", element: <LineChart /> },
      { path: "/charts/gredient-chart", element: <GredientChart /> },
      { path: "/charts/doughnut-pie-chart", element: <DoughnutChart /> },
      { path: "/charts/area-chart", element: <AreaChart /> },
      { path: "/charts/column-chart", element: <ColumnChart /> },
      { path: "/charts/candlestick-chart", element: <CandlestickChart /> },
      { path: "/charts/radialbar-chart", element: <RadialbarChart /> },
      { path: "/ui-components/alert", element: <MuiAlert /> },
      { path: "/ui-components/accordion", element: <MuiAccordion /> },
      { path: "/ui-components/avatar", element: <MuiAvatar /> },
      { path: "/ui-components/chip", element: <MuiChip /> },
      { path: "/ui-components/dialog", element: <MuiDialog /> },
      { path: "/ui-components/list", element: <MuiList /> },
      { path: "/ui-components/popover", element: <MuiPopover /> },
      { path: "/ui-components/rating", element: <MuiRating /> },
      { path: "/ui-components/tabs", element: <MuiTabs /> },
      { path: "/ui-components/tooltip", element: <MuiTooltip /> },
      { path: "/ui-components/transfer-list", element: <MuiTransferList /> },
      { path: "/ui-components/typography", element: <MuiTypography /> },
      { path: "/404", element: <Error /> },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: "/auth/404", element: <Error /> },
      { path: RouterName.auth.login, element: <Login /> },
      { path: RouterName.auth.forgotPassword, element: <ForgotPassword /> },
      { path: RouterName.auth.resetPassword, element: <ResetPassword /> },
      { path: RouterName.auth.maintenance, element: <Maintenance /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
