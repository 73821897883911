export const RouterName = {
  auth: {
    login: "/auth/login",
    signup: "",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password/:token",
    maintenance: "/auth/maintenance",
  },
  home: {
    home: "/dashboards",
  },
  admins: {
    admins: "/admins",
    privileges: "/privileges",
  },
  landing_page: {
    slidershow: "/slide-show",
    news: "/news",
    our_client: "/our_client",
    our_partner: "/our_partner",
    our_team: "/our_team",
    testimonials: "/testimonials",
    products: "/products",
    services: "/services",
    keyfeature: "/key_features",
  },
  settings: {
    settings: "/settings",
    legal_information: "/legal_information",
  },

  industries: {
    industries: "/industries",
    industries_products: "/industries/products/:id",
    industries_services: "/industries/services/:id",
  },
  addresses: {
    addresses: "/addresses",
    addresses_careers: "/addresses/career/:id",
    addresses_emails: "/addresses/email/:id",
    addresses_workinghours: "/addresses/workinghours/:id",
  },

  ContactAs: {
    ContactAs: "/contact_us",
  },

  users: {
    users: "/users",
  },
  admins_logs: {
    admins_logs: "/admin_logs",
  },

};
